<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Generate Promosi
        <div class="card-header-actions">
          <a class="card-header-action" href="promotion" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
              <b-form-group id="name"
                            label="Nama Promosi"
                            label-for="name">
                <b-form-input id="name"
                              type="text"
                              v-model.lazy.trim="$v.form.name.$model"
                              :state="chkState('name')"
                              aria-describedby="nameFeedback"
                              placeholder="Please Enter Name Promotion"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="nameFeedback" v-for="(error , index) in errors.form.name" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="promotion_type"
                          label="Promotion Type"
                          label-for="promotion_type">
                    <b-form-select id="promotion_type"
                      v-model="form.promotion_type"
                      :plain="true"
                      :options="[
                        {value: 'single', text: 'SINGLE'},
                        {value: 'multiple', text: 'MULTIPLE'}]">
                    </b-form-select>
                  </b-form-group>
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    class="mb-3"
                    label="Program Start From and Program End To"
                    label-for="daterange"
                  >
                    <b-input-group
                    >
                      <b-input-group-prepend is-text>
                        <i class="fa fa-calendar"></i>
                      </b-input-group-prepend>
                      <date-range-picker id="daterange" :start="startDate" :end="endDate" @picker="changePicker"></date-range-picker>
                    </b-input-group>
                    <div  v-if="this.startDate == '' " >
                      <p id="dateFeedback" v-for="(error , index) in errors.form.start" :key="index">
                        - {{ error }} <br>
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
                 <b-col sm="6">
                  <b-form-group id="organization"
                                label="Organisasi/Partner"
                                label-for="organization">
                    <b-form-select id="organization"
                      v-model.lazy.trim="form.usage_type"
                      aria-describedby="organizationFeedback"
                      :plain="true"
                      @change="setChange"
                      :options="[
                        {value: 'All', text: 'All User'},
                        {value: 'Treasury', text: 'Treasury User'},
                        {value: 'Partner', text: 'Partner'},
                        {value: 'Organization', text: 'Organization'}]">
                    </b-form-select>
                    <b-form-invalid-feedback id="organizationFeedback" v-for="(error , index) in errors.form.usage_type" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="treasury"
                    label="Spesifik User"
                    v-if="this.form.usage_type == 'Treasury'"
                    label-for="treasury">
                <b-form-select id="treasury"
                  v-model="form.organization"
                  aria-describedby="treasuryFeedback"
                  :plain="true"
                  :options="[
                    {value: 1, text: 'Treasury & VIP Gold'},
                    {value: 2, text: 'Treasury Without VIP Gold'}]">
                </b-form-select>
              </b-form-group>
              <b-form-group id="partner"
                        label="Partner"
                        v-if="this.form.usage_type == 'Partner'"
                        label-for="partner">
                <b-form-select id="partner"
                  v-model="form.organization"
                  :plain="true"
                  :options="partner">
                </b-form-select>
              </b-form-group>
              <b-form-group id="organization"
                        label="Organization"
                        v-if="this.form.usage_type == 'Organization'"
                        label-for="organization">
                <b-form-select id="organization"
                  v-model="form.organization"
                  :plain="true"
                  :options="organization">
                </b-form-select>
              </b-form-group>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="modul"
                          label="Modul"
                          label-for="modul">
                    <b-form-select id="modul"
                      v-model="form.module"
                      aria-describedby="moduleFeedback"
                      :plain="true"
                      :options="[
                        {value: 'Beli Emas', text: 'Beli Emas'}]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="user_type"
                                label="User Type"
                                label-for="user_type">
                    <b-form-select id="user_type"
                      v-model="form.user_type"
                      aria-describedby="usertypeFeedback"
                      :plain="true"
                      :options="[
                        {value: 'All User', text: 'All User'},
                        {value: 'New User', text: 'New User'},
                        {value: 'First Time Buyer', text: 'First Time Buyer'},
                        {value: 'Last Time Buy', text: 'Last Time Buy'},
                        {value: 'Register At', text: 'Register At'}
                      ]"
                    >
                    </b-form-select>
                    <b-form-invalid-feedback id="usertypeFeedback" v-for="(error , index) in errors.form.user_type" :key="index">
                      - {{ error }} <br>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" v-if="form.user_type === 'Register At'">
                  <div>
                    <b-form-group
                      class="mb-3"
                      label="Register Start :"
                      label-for="daterange"
                    >
                      <b-input-group
                      >
                        <date-picker
                          v-model="form.register_start"
                          format="YYYY-MM-DD HH:mm:ss"
                          type="datetime"
                          lang="en"
                          value-type="format"
                          placeholder="YYYY-MM-DD HH:mm:ss"
                        ></date-picker>
                        <!-- <single-picker id="daterange" :start="startDate" @setDate="getPricing"></single-picker> -->
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="6" v-if="form.user_type === 'Register At'">
                  <div>
                    <b-form-group
                      class="mb-3"
                      label="Register End :"
                      label-for="daterange"
                    >
                      <b-input-group
                      >
                        <date-picker
                          v-model="form.register_end"
                          format="YYYY-MM-DD HH:mm:ss"
                          type="datetime"
                          lang="en"
                          value-type="format"
                          :not-before="form.register_start"
                          placeholder="YYYY-MM-DD HH:mm:ss"
                        ></date-picker>
                        <!-- <single-picker id="daterange" :start="startDate" @setDate="getPricing"></single-picker> -->
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-form-group id="day"
                            v-if="form.user_type === 'Last Time Buy'"
                            label="Last Time Buy Day"
                            label-for="day">
                <b-form-input id="day"
                              type="number"
                              v-model="form.day"
                              aria-describedby="dayFeedback"
                              placeholder="0"
                              autocomplete='given-name'
                              autofocus />
              </b-form-group>
              <b-row>
                <b-col sm="12" md="6" lg="6">
                  <b-form-group id="max_allowed-1"
                                label="Number of Users Allowed for Participate"
                                v-if="this.form.promotion_type === 'multiple'"
                                label-for="max_allowed">
                    <b-form-input id="max_allowed-1"
                                  type="number"
                                  v-model="form.promotion_max_usage"
                                  aria-describedby="maxAllowedFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6" lg="6">
                  <b-form-group id="maxattemp-1"
                                label="Maximum Usage Per User"
                                v-if="this.form.promotion_type === 'multiple'"
                                label-for="maxattemp">
                    <b-form-input id="maxattemp-1"
                                  type="number"
                                  v-model="form.promotion_max_attempt"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="sku-1"
                                label="Nilai Transaksi"
                                label-for="sku--1">
                    <b-form-input id="sku-1"
                                  type="number"
                                  v-model="buy[0].name"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="type-1"
                                label="Tipe Promosi"
                                label-for="type-1">
                    <b-form-select id="type-1"
                      v-model="buy[0].type"
                      aria-describedby="usertypeFeedback"
                      :plain="true"
                      :options="[
                        {value: '', text: 'Pilih Tipe Promosi'},
                        {value: 'fixed', text: 'Fixed Price'},
                        {value: 'discount', text: 'Discount'},
                        {value: 'gram', text: 'Add Gram'},
                        {value: 'gram_percent', text: 'Gram Percentage'}]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="amount-1"
                                label="Nilai Promosi"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[0].amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2" v-if="buy[0].type  === 'discount' ||buy[0].type  === 'gram_percent'">
                  <b-form-group id="amount-1"
                                :label="buy[0].type  === 'discount' ? 'Max Amount Promosi' : 'Max Gramation Promosi'"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[0].max_amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="max_allowed-1"
                                label="Quota (Jumlah User)"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="max_allowed">
                    <b-form-input id="max_allowed-1"
                                  type="number"
                                  v-model="buy[0].max_usage"
                                  aria-describedby="maxAllowedFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="maxattemp-1"
                                label="Maximum Usage Per User"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="maxattemp">
                    <b-form-input id="maxattemp-1"
                                  type="number"
                                  v-model="buy[0].max_attempt"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="sku-2"
                                label="Nilai Transaksi"
                                label-for="sku--2">
                    <b-form-input id="sku-2"
                                  type="number"
                                  v-model="buy[1].name"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="type-1"
                                label="Tipe Promosi"
                                label-for="type-1">
                    <b-form-select id="type-1"
                      v-model="buy[1].type"
                      aria-describedby="usertypeFeedback"
                      :plain="true"
                      :options="[
                        {value: '', text: 'Pilih Promosi'},
                        {value: 'fixed', text: 'Fixed Price'},
                        {value: 'discount', text: 'Discount'},
                        {value: 'gram', text: 'Add Gram'},
                        {value: 'gram_percent', text: 'Gram Percentage'}]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="amount-1"
                                label="Nilai Promosi"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[1].amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2" v-if="buy[1].type  === 'discount' ||buy[1].type  === 'gram_percent'">
                  <b-form-group id="amount-1"
                                :label="buy[1].type  === 'discount' ? 'Max Amount Promosi' : 'Max Gramation Promosi'"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[1].max_amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="max_allowed-1"
                                label="Quota (Jumlah User)"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="max_allowed">
                    <b-form-input id="max_allowed-1"
                                  type="number"
                                  v-model="buy[1].max_usage"
                                  aria-describedby="maxAllowedFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="maxattemp-1"
                                label="Maximum Usage Per User"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="maxattemp">
                    <b-form-input id="maxattemp-1"
                                  type="number"
                                  v-model="buy[1].max_attempt"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="sku-3"
                                label="Nilai Transaksi"
                                label-for="sku--3">
                    <b-form-input id="sku-3"
                                  type="number"
                                  v-model="buy[2].name"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="type-1"
                                label="Tipe Promosi"
                                label-for="type-1">
                    <b-form-select id="type-1"
                      v-model="buy[2].type"
                      aria-describedby="usertypeFeedback"
                      :plain="true"
                      :options="[
                        {value: '', text: 'Pilih Promosi'},
                        {value: 'fixed', text: 'Fixed Price'},
                        {value: 'discount', text: 'Discount'},
                        {value: 'gram', text: 'Add Gram'},
                        {value: 'gram_percent', text: 'Gram Percentage'}]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="amount-1"
                                label="Nilai Promosi"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[2].amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2" v-if="buy[2].type  === 'discount' ||buy[2].type  === 'gram_percent'">
                  <b-form-group id="amount-1"
                                :label="buy[2].type  === 'discount' ? 'Max Amount Promosi' : 'Max Gramation Promosi'"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[2].max_amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="max_allowed-1"
                                label="Quota (Jumlah User)"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="max_allowed">
                    <b-form-input id="max_allowed-1"
                                  type="number"
                                  v-model="buy[2].max_usage"
                                  aria-describedby="maxAllowedFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="maxattemp-1"
                                label="Maximum Usage Per User"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="maxattemp">
                    <b-form-input id="maxattemp-1"
                                  type="number"
                                  v-model="buy[2].max_attempt"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="sku-4"
                                label="Nilai Transaksi"
                                label-for="sku--4">
                    <b-form-input id="sku-4"
                                  type="number"
                                  v-model="buy[3].name"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="type-1"
                                label="Tipe Promosi"
                                label-for="type-1">
                    <b-form-select id="type-1"
                      v-model="buy[3].type"
                      aria-describedby="usertypeFeedback"
                      :plain="true"
                      :options="[
                        {value: '', text: 'Pilih Promosi'},
                        {value: 'fixed', text: 'Fixed Price'},
                        {value: 'discount', text: 'Discount'},
                        {value: 'gram', text: 'Add Gram'},
                        {value: 'gram_percent', text: 'Gram Percentage'}]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="amount-1"
                                label="Nilai Promosi"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[3].amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2" v-if="buy[3].type  === 'discount' ||buy[3].type  === 'gram_percent'">
                  <b-form-group id="amount-1"
                                :label="buy[3].type  === 'discount' ? 'Max Amount Promosi' : 'Max Gramation Promosi'"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[3].max_amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="max_allowed-1"
                                label="Quota (Jumlah User)"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="max_allowed">
                    <b-form-input id="max_allowed-1"
                                  type="number"
                                  v-model="buy[3].max_usage"
                                  aria-describedby="maxAllowedFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="maxattemp-1"
                                label="Maximum Usage Per User"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="maxattemp">
                    <b-form-input id="maxattemp-1"
                                  type="number"
                                  v-model="buy[3].max_attempt"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="sku-5"
                                label="Nilai Transaksi"
                                label-for="sku--5">
                    <b-form-input id="sku-5"
                                  type="number"
                                  v-model="buy[4].name"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="type-1"
                                label="Tipe Promosi"
                                label-for="type-1">
                    <b-form-select id="type-1"
                      v-model="buy[4].type"
                      aria-describedby="usertypeFeedback"
                      :plain="true"
                      :options="[
                        {value: '', text: 'Pilih Promosi'},
                        {value: 'fixed', text: 'Fixed Price'},
                        {value: 'discount', text: 'Discount'},
                        {value: 'gram', text: 'Add Gram'},
                        {value: 'gram_percent', text: 'Gram Percentage'}]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="amount-1"
                                label="Nilai Promosi"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[4].amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2" v-if="buy[4].type  === 'discount' ||buy[4].type  === 'gram_percent'">
                  <b-form-group id="amount-1"
                                :label="buy[4].type  === 'discount' ? 'Max Amount Promosi' : 'Max Gramation Promosi'"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[4].max_amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="max_allowed-1"
                                label="Quota (Jumlah User)"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="max_allowed">
                    <b-form-input id="max_allowed-1"
                                  type="number"
                                  v-model="buy[4].max_usage"
                                  aria-describedby="maxAllowedFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="maxattemp-1"
                                label="Maximum Usage Per User"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="maxattemp">
                    <b-form-input id="maxattemp-1"
                                  type="number"
                                  v-model="buy[4].max_attempt"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="sku-6"
                                label="Nilai Transaksi"
                                label-for="sku--6">
                    <b-form-input id="sku-6"
                                  type="number"
                                  v-model="buy[5].name"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="type-1"
                                label="Tipe Promosi"
                                label-for="type-1">
                    <b-form-select id="type-1"
                      v-model="buy[5].type"
                      aria-describedby="usertypeFeedback"
                      :plain="true"
                      :options="[
                        {value: '', text: 'Pilih Promosi'},
                        {value: 'fixed', text: 'Fixed Price'},
                        {value: 'discount', text: 'Discount'},
                        {value: 'gram', text: 'Add Gram'},
                        {value: 'gram_percent', text: 'Gram Percentage'}]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="amount-1"
                                label="Nilai Promosi"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[5].amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2" v-if="buy[5].type  === 'discount' ||buy[5].type  === 'gram_percent'">
                  <b-form-group id="amount-1"
                                :label="buy[5].type  === 'discount' ? 'Max Amount Promosi' : 'Max Gramation Promosi'"
                                label-for="maxattemp">
                    <b-form-input id="amount-1"
                                  type="number"
                                  v-model="buy[5].max_amount"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2" >
                  <b-form-group id="max_allowed-1"
                                label="Quota (Jumlah User)"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="max_allowed">
                    <b-form-input id="max_allowed-1"
                                  type="number"
                                  v-model="buy[5].max_usage"
                                  aria-describedby="maxAllowedFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="2" lg="2">
                  <b-form-group id="maxattemp-1"
                                label="Maximum Usage Per User"
                                v-if="this.form.promotion_type === 'single'"
                                label-for="maxattemp">
                    <b-form-input id="maxattemp-1"
                                  type="number"
                                  v-model="buy[5].max_attempt"
                                  aria-describedby="maxattempFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required} from "vuelidate/lib/validators"
import axios from 'axios'
import dotenv from 'dotenv'
dotenv.config()

// const end = helpers('end', this.form.end_to <= this.form.start_from)
// const decimal = helpers.regex('decimal', /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/)

export default {
  name: "generate",
  data() {
    return {
      startDate: '',
      endDate: '',
      partner: [],
      organization: [],
      form: {
        name: '',
        type: '',
        day: '',
        amount: '',
        user_type: 'All User',
        module: 'Beli Emas',
        register_at: '',
        register_end: '',
        usage_type: 'All',
        max_attempt: '',
        organization: '',
        promotion_type: 'single',
        promotion_max_attempt: 1,
        promotion_max_usage: ''
      },
      arrButton: [],
      buy: [
        {
          name: '5000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        },
        {
          name: '10000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        },
        {
          name: '20000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        },
        {
          name: '50000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        },
        {
          name: '100000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        },
        {
          name: '500000',
          type: '',
          amount: '',
          max_amount: '',
          max_attempt: 1,
          max_usage: ''
        }
      ],
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          name: [],
          prefix: [],
          start: [],
          end: [],
          qty: [],
          max_attempt: [],
          max_allowed: [],
          used_for: [],
          v_type: [],
          expired_type: [],
          amount: []
        },
      },
      date: "",
      isLoading: false,
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  created() {
    this.$http.get('last-promotion').then((res) => {
      let data = res.data.data

      this.buy[0].name = data[0].name
      this.buy[0].type = data[0].type
      this.buy[0].amount = data[0].amount
      this.buy[0].max_amount = data[0].max_amount
      this.buy[0].max_attempt = data[0].max_attempt
      this.buy[0].max_usage = data[0].max_usage

      this.buy[1].name = data[1].name
      this.buy[1].type = data[1].type
      this.buy[1].amount = data[1].amount
      this.buy[1].max_amount = data[1].max_amount
      this.buy[1].max_attempt = data[1].max_attempt
      this.buy[1].max_usage = data[1].max_usage

      this.buy[2].name = data[2].name
      this.buy[2].type = data[2].type
      this.buy[2].amount = data[2].amount
      this.buy[2].max_amount = data[2].max_amount
      this.buy[2].max_attempt = data[2].max_attempt
      this.buy[2].max_usage = data[2].max_usage

      this.buy[3].name = data[3].name
      this.buy[3].type = data[3].type
      this.buy[3].amount = data[3].amount
      this.buy[3].max_amount = data[3].max_amount
      this.buy[3].max_attempt = data[3].max_attempt
      this.buy[3].max_usage = data[3].max_usage

      this.buy[4].name = data[4].name
      this.buy[4].type = data[4].type
      this.buy[4].amount = data[4].amount
      this.buy[4].max_amount = data[4].max_amount
      this.buy[4].max_attempt = data[4].max_attempt
      this.buy[4].max_usage = data[4].max_usage

      this.buy[5].name = data[5].name
      this.buy[5].type = data[5].type
      this.buy[5].amount = data[5].amount
      this.buy[5].max_amount = data[5].max_amount
      this.buy[5].max_attempt = data[5].max_attempt
      this.buy[5].max_usage = data[5].max_usage

      data.map((v, i) => {
        this.arrButton[i] = v
      })
    })
  },
  methods: {
    changePicker (value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    setChange(val) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      if (val === 'Partner') {
        axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/partner-command').then((response) => {
          this.partner = response.data.data
        })
      }

      if (val === 'Organization') {
        axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/organization-command').then((response) => {
          this.organization = response.data.data
        })
      }
    },
    onSubmit() {
      this.validate()
      // if (this.validate()) {
      //   this.$nextTick(() => {
        this.isLoading = true
          const formData =  new FormData();

          formData.append("name", this.form.name);
          formData.append("module", this.form.module);
          formData.append("start_from", this.startDate);
          formData.append("end_to", this.endDate);
          formData.append("used_for", this.form.usage_type === 'All' ? 0 : this.form.organization)
          formData.append("usage_type", this.form.usage_type)
          formData.append("user_type", this.form.user_type)
          formData.append("register_start", this.form.register_start)
          formData.append("register_end", this.form.register_end)
          formData.append("days", this.form.day)
          formData.append("promotion_type", this.form.promotion_type)
          if (this.form.promotion_type === 'single') {
            this.buy.map((v, i) => {
              formData.append("buy[" + i + "][item]", v.name)
              formData.append("buy[" + i + "][type]", v.type)
              formData.append("buy[" + i + "][amount]", v.amount)
              if (v.type === 'discount' || v.type === 'gram_percent') {
               formData.append("buy[" + i + "][max_amount]", v.max_amount)
              }
              formData.append("buy[" + i + "][max_allowed]", v.max_usage)
              formData.append("buy[" + i + "][max_attempt]", v.max_attempt)
            })
            this.arrButton.map((v, i) => {
              formData.append("buy[" + i + "][position]", v.position)
            })
          } else {
            this.buy.map((v, i) => {
              formData.append("buy[" + i + "][item]", v.name)
              formData.append("buy[" + i + "][type]", v.type)
              formData.append("buy[" + i + "][amount]", v.amount)
              if (v.type === 'discount' || v.type === 'gram_percent') {
               formData.append("buy[" + i + "][max_amount]", v.max_amount)
              }
              formData.append("buy[" + i + "][max_allowed]", this.form.promotion_max_usage)
              formData.append("buy[" + i + "][max_attempt]", this.form.promotion_max_attempt)
              formData.append("buy[" + i + "][position]", v.position)
            })
            this.arrButton.map((v, i) => {
              formData.append("buy[" + i + "][position]", v.position)
            })
          }

          this.$http.post(`promotion`, formData)
          .then(() => {
            this.isLoading  = false
            this.$router.push("/promotion");
            this.$toasted.success('Promotion successfully created!')
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.name = this.errors.message.name;
                this.errors.form.v_type = this.errors.message.type;
                this.errors.form.qty = this.errors.message.quantity;
                this.errors.form.max_attempt = this.erros.message.max_attempt;
                this.errors.form.amount = this.errors.message.amount;
                this.errors.form.prefix = this.errors.message.prefix;
                this.errors.form.start = this.errors.message.start_from;
              }else if(this.errors.status == 400){
                this.errors.message = error.response.data.meta.message;
                this.$swal.fire(
                  'Failed!',
                  this.errors.message,
                  'error'
                )
              }
            }
          })
      //   })

      // }
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  },
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.mx-datepicker {
  width: 100% !important;
}

#dateFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
